import React from "react";
import Container from "../container/Container";

const OurConnection = ({ uni }) => {
  return (
    <Container fluid extraclassName="py-8 bg-[#f78c45]">
      <div className="w-[70%]  mx-auto ">
        <h1 className="md:py-10 py-2 lg:text-[40px] text-black md:text-[30px] text-[18px] uppercase font-semibold md:px-10 px-2 bg-white text-center">
          Our Students Success Story
        </h1>
        <div className="lg:flex block md:py-14 py-6 justify-between text-center">
          <div className="lg:w-[30%]">
            <p className="font-bold text-[35px] text-red-700">
              {uni && uni.studentEnroll}+
            </p>
            <p className="font-semibold">Students Enrolled</p>
          </div>
          <div className="lg:w-[30%] ">
            <p className="font-bold text-[35px] text-red-700">
              {uni && uni.registeredInstructor}+
            </p>
            <p className="font-semibold">Registered Instructors</p>
          </div>
          <div className="lg:w-[30%] ">
            <p className="font-bold text-[35px] text-red-700">
              {uni && uni.successRate}%
            </p>
            <p className="font-semibold">Success Rate</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OurConnection;
