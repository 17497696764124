import React, { useEffect } from "react";
import "./index.css";

const MotionText = ({ uni }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const show = document.querySelector("span[data-show]");
      const next =
        show.nextElementSibling || document.querySelector("span:first-child");
      const up = document.querySelector("span[data-up]");

      if (up) {
        up.removeAttribute("data-up");
      }

      show.removeAttribute("data-show");
      show.setAttribute("data-up", "");

      next.setAttribute("data-show", "");
    }, 2000);

    // Cleanup function to clear the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures the effect runs once after the initial render

  return (
    <>
      <h2 className="texth2">
        <h2 className="uniName"> {uni ? uni.university : ""}</h2>
       
        <div className="mask">
          <span data-show>a theater</span>
          <span>a gym</span>
          <span>a concert hall</span>
          <span>an arcade</span>
        </div>
      </h2>
    </>
  );
};

export default MotionText;
