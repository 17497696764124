import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import image1 from "./s-blogimg-01.png";
import image2 from "./s-blogimg-02.png";
function Footer() {
  return (
    <>
      {/* <NewsLetter/> */}
      <div className="w-full bg-[#C73336]  md:pt-[70px] pt-[10px] md:px-3 px-2">
        <div className="container mx-auto">
          <div className="flex justify-between flex-wrap">
            {/* About Us Section Start */}
            <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%]">
              <h4 className="text-black py-2 border_orange font-bold">
                About Us
              </h4>
              <p className="text-white">
                Karnavati University supports its students to succeed at every
                level to become well-rounded individuals and skilled
                professionals. The Trust runs six colleges–Unitedworld Institute
                of Design, Unitedworld School of Business, Unitedworld School of
                Liberal Arts & Mass Communication, Unitedworld School of Law,
                Unitedworld School of Computational Intelligence and Karnavati
                School of Dentistry
              </p>
              <div className="flex gap-3 py-2">
                <div className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47]
                 w-[36px] h-[36px] rounded-full hover:bg-red-400 py-1">
                  <FaFacebookF className="text-[rgb(255,223,21)]" />
                </div>
                <div className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47]
                 w-[36px] h-[36px] rounded-full hover:bg-red-400 py-1">
                  <FaInstagram className="text-[rgb(255,223,21)]" />
                </div>
                <div className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47]
                 w-[36px] h-[36px] rounded-full hover:bg-red-400 py-1">
                  <FaTwitter className="text-[rgb(255,223,21)]" />
                </div>
              </div>
            </div>

            {/* EnD About Us Section Start */}

            {/* Latest Post Start */}
            <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%]">
              <h4 className="text-black py-2 border_orange font-bold">
                Latest Post
              </h4>

              <Link to="/blogpost1">
                <div className="flex py-2 gap-2">
                  <div>
                    <img src={image1} alt="footer_img" />
                  </div>
                  <div className="ml-2">
                    <p className="mb-1">
                      <a href="#" className="no-underline text-white">
                        Karnavati University's Journey of Empowering Future
                        Leaders
                      </a>
                    </p>
                    <span className="text-black-400">15 March,2024</span>
                  </div>
                </div>
              </Link>
              <Link to="/blogpost2">
                <div className="flex gap-2">
                  <div>
                    <img src={image2} alt="footer_img" />
                  </div>
                  <div className="ml-1">
                    <p className="mb-1">
                      <a href="#" className="no-underline text-white">
                        Why Karnavati University Is the Top Choice for Future
                        Leaders
                      </a>
                    </p>
                    <span className="text-black-400">20 march,2024</span>
                  </div>
                </div>
              </Link>
            </div>

            {/* Latest Post EnD */}

            {/* Our Links Start*/}

            <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[70%] lg:w-[40%] ml-2">
              <h4 className="text-black py-2  font-bold  text-center underline-offset-2">
                Our Links
              
              </h4>
              <div className=" border-t-[#ff7350]  py-2  text-center border-t  flex justify-center"></div>
              <div className="w-full flex justify-between gap-8">
              <ul className="pl-0 text-white">
               
 <li>
                 <a href="# text-white no-underline">
                   Mca mtech hotel management{" "}
                 </a>
               </li>
               <li>
                 <a href="# text-white no-underline">
                   Karnavati university MBA
                 </a>
               </li>
               <li>
                 <a href="# text-white no-underline">
                   Karnavati university Placements
                 </a>
               </li>
               <li>
                 <a href="# text-white no-underline">CUET</a>
               </li>
               <li>
                 <a href="# text-white no-underline">
                   Karnavati university Gandhinagar
                 </a>
               </li>
               <li>
                 <a href="# text-white no-underline">Bsc Computer science</a>
               </li>
               <li>
                 <a href="# text-white no-underline">BBA LLB Hons</a>
               </li>
               <li>
                 <a href="# text-white no-underline">BBA specialization</a>
               </li>
               <li>
                 <a href="# text-white no-underline">Bba data Analytics</a>
               </li>
               <li>
                 <a href="# text-white no-underline">
                   Premium university of Ahemdabaad
                 </a>
               </li>
               <li>
                 <a href="# text-white no-underline">
                   Top university in ahemdabad
                 </a>
               </li>
               <li>
                 <a href="# text-white no-underline">
                   Best University of gujarat
                 </a>
               </li>
               <li>
                 <a href="# text-white no-underline">Bsc it</a>
               </li>
               <li>
                 <a href="# text-white no-underline">
                   Best Law college of gujarat
                 </a>
               </li>
               <li>
                 <a href="# text-white no-underline"></a>
               </li>
               <li>
                 <a href="# text-white no-underline"></a>
               </li>
               <li>
                 <a href="# text-white no-underline"></a>
               </li>
             </ul>
             <ul>
             <li className="py-1">
                 <a href="#" className="text-white no-underline">
                   Karnavati university
                 </a>
               </li>
               <li className="py-1">
                 <a href="#" className="text-white no-underline">
                   Karnavati university Gandhinagar
                 </a>
               </li>
               <li className="py-1">
                 <a href="#" className="text-white no-underline">
                   Karnavati university number 
                  
                 </a>
               </li>
               <li className="py-1">
                 <a href="#" className="text-white no-underline">
                   Karnavati university fee structure
                  
                 </a>
               </li>
               <li className="py-1">
                 <a href="#" className="text-white no-underline">
                   Scholarships details Karnavati university
                 </a>
               </li>
               <li className="py-2">
                 <a href="#" className="text-white no-underline">
                   Karnavati university address
                 </a>
               </li>
               <li>
                 <a href="#" className = "text-white no-underline">
                   Karnavati university reviews{" "}
                 </a>
               </li>
               <li>
                 <a href="#" className = "text-white no-underline">
                   Karnavati university Btech{" "}
                 </a>
               </li>
               <li>
                 <a href="#" className = "text-white no-underline">
                   Karnavati university bba{" "}
                 </a>
               </li>
               <li>
                 <a href="#" className = "text-white no-underline">
                   Best university in Uttarakhand
                 </a>
               </li>
               <li>
                 <a href="#" className = "text-white no-underline">Karnavati university</a>
               </li>
               <li>
                 <a href="#" className = "text-white no-underline">Btech BBA BCA </a>
               </li>
             </ul>
              </div>
              
            </div>
            

            {/* Our Links EnD*/}

            {/* Contact Us Start */}

            {/* Contact Us EnD*/}
          </div>
        </div>
        <div className="w-full  lg:mb-0 sm:w-[100%] md:w-[100%] lg:w-[100%] md:mt-[2rem] mb-[2rem] md:px-4 px-2 py-8">
          <p className="text-white text-[12px]">
            <span className="font:bold text-[15px] text-black font-bold">
              Disclaimer -{" "}
            </span>{" "}
            The purpose of aaopadhe is to provide accurate and unbiased
            information about universities and their programs of study to
            admission aspirants. The content on the aaopadhe website, including
            texts, graphics, images, blogs, videos, university logos, and other
            materials (collectively referred to as "Content"), is provided for
            informational purposes only. The content is not intended to replace
            or substitute for any offerings or services provided by our academia
            partners. We do not endorse or deliberately infringe upon any
            intellectual property or associated rights of . The information
            provided by aaopadhe on www.aaoapdhe.in, as well as any mobile or
            other applications, is offered in good faith and to the best of our
            knowledge and abilities. However, we make no representation or
            warranty, whether express or implied, regarding the accuracy,
            adequacy, validity, reliability, or completeness of the information
            provided on the website or in our applications. While we strive to
            maintain accurate and up-to-date information, errors or omissions
            may occur. Users can also visit the official website of for the most
            up-to-date and accurate information regarding admissions, programs,
            courses, fees, deadlines, and any other relevant details. By using
            the aaopadhe website or any of our applications, you acknowledge
            that you have read, understood, and agreed to the terms and
            conditions of this disclaimer. If you do not agree with any part of
            this disclaimer, we kindly request that you refrain from using our
            platform. Please be aware that this disclaimer may be subject to
            change without prior notice. We recommend reviewing the disclaimer
            periodically for any updates or modifications. If you have any
            questions or concerns regarding this disclaimer, please contact us.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
