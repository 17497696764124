import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./index.css";

// Import required modules
import { FreeMode, Navigation, Autoplay } from "swiper/modules";

const NewsSlider = ({ uni }) => {
  return (
    <div className="pt-[4rem] pb-[5rem]">
      <h1 className="text-black-500 font-bold text-2xl md:ml-[5rem] ml-[3.5rem]">
        Our students success story
      </h1>
      <div className="w-full flex justify-center pb-[30px] pt-[25px] px-4 overflow-hidden">
        <div className="w-[90%]">
          <Swiper
            slidesPerView={6}
            spaceBetween={30}
            freeMode={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            navigation={true}
            modules={[FreeMode, Navigation, Autoplay]}
            className="mySwiper"
          >
            {uni &&
              uni.successStory.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="w-[100%] h-[13rem] border-2 shadow-2xl justify-center">
                    <iframe
                      width="400"
                      height="225"
                      src={data}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default NewsSlider;
