import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  

  return (
    <div className="w-[100%] flex justify-center">
      <div className="bg-white-100 p-6 rounded-lg shadow-2xl w-[87%] my-12 ">
        <h1 className="text-2xl font-bold mb-8 mt-4 text-center ">Privacy Policy</h1>
       
      
        <div className="blog-content">
          <h2 className="font-bold mt-4">Introduction</h2>
          <p>
            This privacy policy outlines how collects, uses, and protects
            personal information collected from users of our website
            https://aaopadhe.in By using our website, you agree to the terms of
            this policy.
          </p>
          <h3 className="text-md font-bold mt-4">Information Collected</h3>
          <p>
            We may collect personal information when you visit our website, such
            as your name, email address, phone number, and other contact
            details. We may also collect non-personal information such as your
            IP address, browser type, and operating system.
          </p>
          <h3 className="text-md font-bold mt-4">
            2. Cookies and Tracking Technologies
          </h3>
          <p>
            Our website may use cookies and other tracking technologies to
            improve the user experience and provide personalized content.
            Cookies are small text files that are placed on your computer or
            device when you visit our website. They allow us to track your
            browsing behavior and preferences. You can disable cookies in your
            browser settings, but this may affect your ability to use some
            features of our website.
          </p>
          <h3 className="text-md font-bold mt-4">3. Use of Information</h3>
          <p>
            We use the information collected from our website to provide
            educational and administrative services to our students, faculty,
            and staff. We may also use the information for marketing purposes or
            to improve the user experience on our website.
          </p>
          <h3 className="text-md font-bold mt-4">
            4. Disclosure of Information
          </h3>
          <p>
            We may share personal information with third-party service providers
            to help us manage our website or provide other services. We may also
            disclose personal information in response to a legal request or to
            protect our rights or property.
          </p>
          <h3 className="text-md font-bold mt-4">5. Security</h3>
          <p>
            We take reasonable measures to protect personal information from
            unauthorized access, use, or disclosure. However, we cannot
            guarantee the security of information transmitted over the internet.
          </p>
          <h3 className="text-md font-bold mt-4">6. Your Rights</h3>
          <p>
            You have the right to access, correct, and delete personal
            information we have collected from you. To exercise these rights,
            please contact us at .
          </p>
          <h2 className="font-bold mt-4">Changes to this Policy</h2>
          <p>
            We may update this privacy policy from time to time. The latest
            version of the policy will be posted on our website.
          </p>
          <h2 className="font-bold mt-4">Conclusion</h2>
          <p>
            We take your privacy seriously and are committed to protecting your
            personal information. If you have any questions or concerns about
            this policy, please contact us at .
          </p>
          
        </div>
        {/* SEO meta tags */}
       
      </div>
    </div>
  );
};

export default PrivacyPolicy;
