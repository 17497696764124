import React from "react";
import { Link } from "react-router-dom";
const Disclaimer = () => {
  
  const smoothscrool = () =>{
window.scrollTo({
  top: 0, // Yahan aap apne desired scroll position ko specify kar sakte hain
  behavior: "smooth", // Smooth scrolling ke liye
})
  }

  return (
    <div className="bg-white text-black md:p-2 px-[3px] ">
   
        <h1 className="text-center text-black md:text-[15px] ">
            I authorize a representative to contact me via phone and/or email.
            This will override registry on DND/NDNC. Copyright © 2023 | |
            <Link to="/privacyPolicy" onClick={smoothscrool}><span  className="hover:text-orange-500">Privacy Policy</span></Link>
          </h1>
    </div>
  );
};

export default Disclaimer;
